import { useEffect } from 'react';

import { PW_REMOVE_LOADING_LABEL } from '@client/forms/inputs/ProsemirrorLeveler/plugins/power-words';

const { useStoreState } = require('easy-peasy');

/**
 * A custom hook to prevent the user from navigating away from the page
 * while specific operations are in progress.
 *
 * For example, blocks page unload when the `loadingLabel` is set to 'Removing Power Word'.
 * Additional conditions can be added by extending the `if` check.
 */
const usePreventPageUnload = () => {
  const drawerLoadingSpinner = useStoreState((state) => state.drawer.loadingSpinner);

  const loadingLabel = drawerLoadingSpinner.label;

  useEffect(() => {
    function handleBeforeUnload (event) {
      event.preventDefault();
      // eslint-disable-next-line no-param-reassign
      event.returnValue = ''; // Necessary for most modern browsers
    }

    if (loadingLabel === PW_REMOVE_LOADING_LABEL) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    }

    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [loadingLabel]);

  return null;
};

export default usePreventPageUnload;
