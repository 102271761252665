import cuid from 'cuid';
import gql from 'graphql-tag';
import { noop, get, reduce, words } from 'lodash-es';
import { Plugin, PluginKey, TextSelection } from 'prosemirror-state';

import { apolloClient } from '@client/apollo-client';
import { queries, mutations } from '@client/common/graph';
import { WordDefinition } from '@client/common/schema';
import { store } from '@client/common/store';
import { POWER_WORD_TIER, POWER_WORDS } from '@client/utils/constants';
import { logError } from '@client/utils/log';
import * as toast from '@client/utils/toast';
import { error } from '@client/utils/toast';

import { passThroughTransaction } from './helpers';
import { textInSelection, isDeletingText, getPowerWordsNodes, getBlock, countBlockType } from '../formats/helpers';
import decorateWords from '../plugins/decorate-words';

const storeActions = store.getActions();
const { switch: switchDrawer, close: closeDrawer } = storeActions.drawer;
const setStorePowerWords = storeActions.setPowerWords;
let isMakingPowerWord = false; // This is a flag to prevent calling the same transaction twice.

// Exported for testing
export const NOT_SINGLE_WORD_ERROR = 'Select a single word to add a Power Word';
export const NOT_IN_DATABASE_ERROR = 'Contact the Content Tools team to add this word.';
export const ALREADY_ADDED_WORD_ERROR = 'This word has already been selected. Reload the page to see the latest changes.';
export const CANT_DELETE_POWER_WORD_ERROR = 'Your selection contains Power Words. To remove, use the Power Words tool.';
export const ERROR_RETRIEVING_POWER_WORDS = 'There was an error loading Power Words. Contact the Content Tools team to learn more.';
export const EARLIER_WORD_FORM_ERROR = 'There is a form of your selection earlier in the text. Please select the earlier word form.';

// Power Words transactions meta names
// Exported for testing
export const PW_MODE_ON = 'PW_MODE_ON';
export const PW_MAKE_POWER_WORD = 'PW_MAKE_POWER_WORD';
export const PW_CHANGE_DEFINITION = 'PW_CHANGE_DEFINITION';
export const PW_REMOVE_POWER_WORD = 'PW_REMOVE_POWER_WORD';
export const PW_REMOVE_LOADING_LABEL = 'Removing Power Word';

/**
 * Updates the isMakingPowerWord flag to prevent calling the same transaction twice.
 */
export function setIsMakingPowerWord (value) {
  isMakingPowerWord = !!value;
}

/**
 * Enables power words mode and fill up the power words array.
 * Exported for testing.
 * @param {Array} powerWords initial power words array
 */
export function enablePowerWordsMode (powerWords) {
  if (!powerWordsPlugin.props.powerWordsModeOn) {
    powerWordsPlugin.props.powerWordsModeOn = true;
    setStorePowerWords(powerWords);
  }
}

/**
 * Disables power words mode and clears the power words array.
 * Exported for testing.
 */
export function disablePowerWordsMode () {
  if (powerWordsPlugin.props.powerWordsModeOn) {
    powerWordsPlugin.props.powerWordsModeOn = false;
    setStorePowerWords([]);
  }
}

/**
 * Fetch a single word.
 * Exported for testing.
 * @param {String} wordForm word to fetch
 * @param {Boolean} isPowerWord whether the word is a power word
 * @param {Object} client apollo client (passed in for testing)
 * @returns {Object|undefined} the fetched word. Ex: { uid: '0x123', __typename: 'Word' }
 */
export async function fetchWord (wordForm, isPowerWord = false, client = apolloClient) {
  const wordForms = { eq: [wordForm?.toLowerCase()] };
  const wordTier = { eq: POWER_WORD_TIER };
  const wordDefinitions = { gt: 0 };
  const powerWordFilter = { wordForms, wordTier, wordDefinitions };
  const nonPowerWordFilter = {
    wordForms,
    or: [
      { wordTier: { exists: false } },
      { not: { wordTier } },
      { wordDefinitions: { eq: 0 } }
    ]
  };
  const filter = isPowerWord ? powerWordFilter : nonPowerWordFilter;
  return get(await client.query({
    query: queries.words,
    variables: { filter }
  }), 'data.words[0]');
}

/**
 * Fetch all power words present in an article level.
 * Exported for testing.
 * @param {Object} formData ArticleLevel
 * @param {object} view ProseMirror View
 * @param {Object} client apollo client (passed in for testing)
 * @returns {Array} powerWords
 */
export async function fetchPowerWords (formData, view, client = apolloClient) {
  // Look at the size of the current Prosemirror state to determine whether
  // there is text. We look at this instead of formData.text, because formData
  // is only updated when the Prosemirror EditorView is instantiated. That
  // means it will have the correct uid, but won't have any text we've written
  // since the form was rendered.
  return (view?.state?.doc?.nodeSize ?? 0) > 0
    ? get(await client.query({
      query: queries.powerWords,
      variables: { uid: formData.uid, field: 'text' },
      fetchPolicy: 'network-only' // Always fetch the latest from the server.
    }), 'data.powerWords')
    : [];
}

/**
 * Create a new blank word definition and add it to the Word and the ArticleLevel
 * @param {String} id the new WordDefinition id
 * @param {Object} matchingWord info about the word founded in the db
 * @param {Object} formData ArticleLevel
 * @param {Object} parent Article
 * @param {Object} client apollo client (passed in for testing)
 */
async function createWordDefinition (id, matchingWord, formData, parent, client = apolloClient) {
  // Create a new blank WordDefinition with the specified id we are passing in.
  const blankWordDefinition = WordDefinition.defaults(
    id,
    { wordForm: matchingWord.wordForm },
    matchingWord // Contains the uid for the Word (not WordDefinition).
  );

  // Add the new blank WordDefinition to the Word
  await client.mutate({
    mutation: mutations.setWord,
    variables: {
      input: {
        uid: matchingWord.uid, // Word uid.
        wordTier: POWER_WORD_TIER,
        wordDefinitions: [blankWordDefinition.server]
      }
    }
  });

  // updatedAt and tabId used to prevent users overriding each other's Article levels.
  const updatedAt = store.getState()?.articleLevelsConcurrency?.data[formData.uid]?.updatedAt;
  const tabId = store.getState()?.articleLevelsConcurrency.data?.tabId;
  // Add the new blank WordDefinition to the ArticleLevel
  await client.mutate({
    mutation: mutations.setContent,
    variables: {
      input: {
        uid: parent.uid, // Article uid.
        articleLevels: {
          uid: formData.uid, // ArticleLevel uid.
          updatedAt,
          tabId,
          wordDefinitions: [{ uid: blankWordDefinition.server.uid }]
        }
      },
    }
  });
}

/**
 * Validate if the selection is a single word
 * Export for testing
 * @param {Object} state ProseMirror state
 * @returns {Object|Boolean} with { selection, selectedWord } or false if not valid
 */
export function validateSelection (state) {
  const { selection: userSelection, doc, tr } = state;
  const { from: userSelectionFrom, to: userSelectionTo } = userSelection;
  const textInSelection = doc.cut(userSelectionFrom, userSelectionTo).textContent;
  const wordsInSelection = words(textInSelection);

  if (wordsInSelection.length !== 1) return false;

  let selection = userSelection;
  let selectedWord = textInSelection;
  const selectedWordTrimmed = selectedWord.trim();

  if (selectedWord.length !== selectedWordTrimmed.length) {
    const startIndex = userSelectionFrom + selectedWord.indexOf(selectedWordTrimmed);
    const endIndex = startIndex + selectedWordTrimmed.length;
    const trimmedSelection = TextSelection.create(doc, startIndex, endIndex);
    tr.setSelection(trimmedSelection);
    selection = tr.curSelection;
    selectedWord = selectedWordTrimmed;
  }

  return { selection, selectedWord };
}

function getPlainText (rawText) {
  if (!rawText) return undefined;
  if (rawText.type === 'text') {
    return rawText.text + ' ';
  }
  let text = '';
  rawText.content?.forEach((child) => {
    text += getPlainText(child);
  });
  return text;
}

/**
 * Validates if the base form of the target word appears earlier in the text.
 * @param {String} text - The text containing the words.
 * @param {String} targetWord - The word to check in the text.
 * @param {String} baseFormWord - The base form of the word to find.
 * @returns {Boolean} - Returns true if the base form of the target word is found earlier in the text, otherwise false.
 */
function isBaseFormEarlier (text, targetWord, baseFormWord) {
  const targetIndex = text.indexOf(targetWord);
  if (targetIndex === -1) {
    return false;
  }

  const earlierText = text.slice(0, targetIndex);
  const baseFormRegex = new RegExp(`\\b${baseFormWord}\\b`);

  return baseFormRegex.test(earlierText);
}

/**
 * Validate if the selected word is a power word (POWER_WORD_TIER)
 * Exported for testing
 * @param {String} word selected word
 * @param {Object} client apollo client (passed in for testing)
 * @returns {Object} { powerWord, existingNonPowerword, error }
 */
export async function validatePowerWord (word, formData, client = apolloClient) {
  // Fetch the selected word from the database because we need its uid to check
  // if that word (or any of its forms) it's present in the power word list
  const fetchedPowerWord = await fetchWord(word, true, client);
  // Get the list of power words in this article level
  const powerWords = await fetchPowerWords(formData, { state: { doc: { nodeSize: 1 } } }, client);
  // Check if the selected word is in the database
  const fetchedNonPowerword = !fetchedPowerWord
    ? await fetchWord(word, false, client)
    : undefined;
  // If the selected word is not a power word (POWER_WORD_TIER) in our database,
  // AND not in our database at all, invalidate it
  if (!fetchedPowerWord && !fetchedNonPowerword) {
    return {
      error: NOT_IN_DATABASE_ERROR,
      existingNonPowerWord: fetchedNonPowerword
    };
  }
  // If the selected word is not the FIRST form of the word AND is a power word
  // in our database, invalidate it
  if (fetchedPowerWord && !powerWords.find((pw) => pw.wordForm === word)) {
    return { error: EARLIER_WORD_FORM_ERROR };
  }

  // If the selected word is neither the base form of the word nor a power word
  // in the database
  const plainText = getPlainText(formData.rawText);
  if (plainText && fetchedNonPowerword && isBaseFormEarlier(plainText, word, fetchedNonPowerword.headWord)) {
    return { error: EARLIER_WORD_FORM_ERROR };
  }

  // Return the matching power word from the power word list
  const returnValue = powerWords.find((powerWord) => powerWord.uid === fetchedPowerWord?.uid);
  return { powerWord: returnValue, existingNonPowerWord: fetchedNonPowerword };
}

/**
 * Validate selected word as a potential power word
 * @param {String} selectedWord
 * @returns {(Object|Boolean)} potentialPowerWord or false if not valid
 */
async function validatePotentialPowerWord (selectedWord, formData) {
  const { error, powerWord, existingNonPowerWord } = await validatePowerWord(selectedWord, formData);
  if (powerWord) {
    if (powerWord.wordDefinition === null) return powerWord;
    else {
      toast.error(ALREADY_ADDED_WORD_ERROR);
      return false;
    }
  }

  // Display error from validatePowerWord.
  if (error) {
    toast.error(error);
    return false;
  }

  return {
    ...existingNonPowerWord,
    wordForm: selectedWord.toLowerCase()
  };
}

/**
 * Handle the make power word transaction
 * @param {Object} tr ProseMirror transaction
 * @param {Object} view ProseMirror View
 * @param {Object} formData ArticleLevel
 * @param {Object} parent Article
 */
async function handleMakePowerWordTransaction (tr, view, formData, parent) {
  const { id, selection, selectedWord } = tr.getMeta(PW_MAKE_POWER_WORD);
  try {
    storeActions.drawer.setLoadingSpinner({ loadingState: true, label: 'Adding Power Word' });
    const matchingWord = await validatePotentialPowerWord(selectedWord, formData);
    if (matchingWord) {
      await createWordDefinition(id, matchingWord, formData, parent);
      const content = view.state.doc.content.textBetween(selection.from, selection.to);
      const textNode = view.state.schema.text(content);
      const powerWordBlock = getBlock('powerWord_block', view.state).create({ id }, textNode);
      tr.replaceWith(selection.from, selection.to, powerWordBlock);
      // Clear the transaction meta data because we are going to dispatch this transaction again
      // and we don't want to trigger the same transaction again
      tr.setMeta(PW_MAKE_POWER_WORD, null);
      view.dispatch(tr);
      setStorePowerWords(await fetchPowerWords(formData, view));
    }
  } catch (err) {
    error(`Error creating Power Word ${selectedWord}.`);
    logError(`Error creating Power Word ${selectedWord}: ${err.message}`);
  } finally {
    storeActions.drawer.setLoadingSpinner({ loadingState: false });
  }
}

/**
 * Handle the power words mode transaction (toggle ON/OFF)
 * @param {Object} tr ProseMirror transaction
 * @param {Object} view ProseMirror View
 * @param {Object} formData ArticleLevel
 * @param {Object} parent Article
 */

async function handlePowerWordsModeTransaction (tr, view, formData, parent) {
  const modeOnTransaction = tr.getMeta(PW_MODE_ON);

  if (modeOnTransaction?.powerWordsModeOn) {
    try {
      storeActions.setGlobalLoadingSpinner({ loadingState: true, label: 'Loading Power Words' });
      enablePowerWordsMode(await fetchPowerWords(formData, view));
      openDrawer(view, formData, parent);
    } catch (error) {
      toast.error(ERROR_RETRIEVING_POWER_WORDS);
      console.error('Loading Power Words error: ', error);
    } finally {
      storeActions.setGlobalLoadingSpinner({ loadingState: false });
    }
  } else {
    disablePowerWordsMode();
    closeDrawer();
  }
}

/**
 * Get cached wordDefinitions for a specific article level.
 * @param {string} articleId id for the Article
 * @param {string} levelUid uid for the ArticleLevel
 * @param {Object} client apollo client (passed in for testing)
 * @returns {array} of wordDefinitions
 */
function getCachedDefinitions (articleId, levelUid, client = apolloClient) {
  const cachedArticle = client.readFragment({
    id: `Article:{"id":"${articleId}"}`,
    fragment: gql`
      fragment ArticleLevelWordDefinitions on Article {
        articleLevels {
          uid
          wordDefinitions { id uid wordForm }
        }
      }
    `
  });

  const articleLevel = cachedArticle?.articleLevels?.find((level) => level.uid === levelUid);
  return articleLevel?.wordDefinitions || [];
}

/**
 * Get cached powerWordQuestions for a specific word definition. Exported for testing.
 * @param {string} definitionId id for the WordDefinition
 * @param {string} articleId id for the Article
 * @param {Object} client apollo client (passed in for testing)
 * @returns {Array} of questions with { uid, __typename }
 */
export function getPowerWordQuestions (definitionId, articleId, client = apolloClient) {
  // Get questions associated with this power word definition.
  const cachedWordQuestions = get(client.readFragment({
    id: `WordDefinition:{"id":"${definitionId}"}`,
    fragment: gql`
      fragment WordDefinitionPowerWordQuestions on WordDefinition {
        powerWordQuestions { uid __typename }
      }
    `
  }), 'powerWordQuestions', []);

  // Get questions associated with any assessments on this article.
  const cachedAssessmentQuestions = reduce(get(client.readQuery({
    query: gql`
      query ReadArticleAssessments($id: ID!) {
        content(id: $id) {
          attached {
            contentType
            ...on Assessment {
              assessmentType
              levels {
                questions {
                  uid
                }
              }
            }
          }
        }
      }
    `,
    variables: { id: articleId }
  }), 'content.attached', []), (acc, attachedItem) => {
    // Reduce over each attached piece of content, filtering them to only look
    // at Power Word Activities. Then go through the levels and questions of
    // the filtered assessments, returning an object of question uids. These
    // questions are, by definition, the ones that exist in the latest draft
    // of the assessments, rather than the snapshots.
    if (attachedItem.contentType !== 'ASSESSMENT' || attachedItem.assessmentType !== 'POWER_WORDS_ACTIVITY') {
      return acc;
    }

    attachedItem.levels.forEach((level) => level.questions.forEach((question) => {
      acc[question.uid] = question.__typename;
    }));

    return acc;
  }, {});

  // Filter the list of questions associated with the word definition to only
  // include questions that are in the latest draft of an assessment attached
  // to this article (i.e. NOT in any assessment snapshots).
  const filteredQuestions = cachedWordQuestions.filter((question) => {
    return !!cachedAssessmentQuestions[question.uid];
  });

  return filteredQuestions;
}

/**
 * Determine if a word definition has associated questions. If so, ask the user
 * if they want to delete them.
 * Exported for testing.
 * @param {Object} definition word definition
 * @param {string} articleId
 * @returns {Object} with { hasQuestions, shouldRemoveQuestions }
 */
export function checkPowerWordQuestionRemoval (questions, wordForm) {
  const hasQuestions = questions.length > 0;
  let shouldRemoveQuestions = false;

  if (hasQuestions) {
    const text = questions.length === 1
      ? 'is 1 associated question'
      : `are ${questions.length} associated questions`;

    shouldRemoveQuestions = window.confirm(`
      There ${text} for "${wordForm}".
      Removing this word will also remove the questions. Continue?
    `);
  }

  return {
    hasQuestions,
    shouldRemoveQuestions
  };
}

/**
 * Remove Power Word questions from the Power Word Activity associated with
 * this article. Questions may be PowerWordAssociation or PowerWordExample
 * @param {Object} assessment power word activity
 * @param {Array} questions array of { uid, __typename }
 * @param {Object} client apollo client (passed in for testing)
 */
async function removePowerWordQuestions (assessment, questions, client = apolloClient) {
  // Here we have only one level: the one corresponding to the current article level (same gradeBand)
  const assessmentLevel = assessment.levels[0];
  // Iterate through all specified questions
  await Promise.all((questions.map(async (question) => {
    // Remove the relationship between question and assessment level
    client.mutate({
      mutation: mutations.unsetContent,
      variables: {
        input: {
          uid: assessment.uid,
          levels: {
            uid: assessmentLevel.uid,
            questions: {
              uid: question.uid
            }
          }
        }
      }
    });
    // Delete the question
    client.mutate({
      mutation: mutations.deleteAssessmentQuestion,
      variables: {
        uid: question.uid
      }
    });
  })));
}

/**
 * Determine if a selected bit of text is a single power word. Used when attempting
 * to delete power words. Exported for testing.
 * @param {Object} selection
 * @param {Object} formData ArticleLevel
 * @param {Object} parent Article
 * @param {Object} client apollo client (passed in for testing)
 * @returns {Boolean}
 */
export function isSinglePowerWord (selection, formData, parent, client = apolloClient) {
  if (!formData || !parent) {
    return false;
  }

  const [text] = textInSelection(selection);
  const wordDefinitions = getCachedDefinitions(parent.id, formData.uid, client);
  const isPowerWord = wordDefinitions.find((powerWord) => powerWord.wordForm === text);

  return !!isPowerWord;
}

/**
 * Manage Power Words transactions based on transaction meta info
 * @param {Object} transaction ProseMirror transaction
 * @param {Object} view ProseMirror View
 * @param {Object} formData ArticleLevel
 * @param {Object} parent Article
 * @returns {Boolean} true if we continue the transaction, false to cancel it
 */
async function manageTransactions (tr, view, formData, parent) {
  // Handle power words mode transaction
  const modeOnTransaction = tr.getMeta(PW_MODE_ON);
  if (modeOnTransaction) {
    await handlePowerWordsModeTransaction(tr, view, formData, parent);
    return true;
  }

  // Handle make power word transaction
  const makePowerWordTransaction = tr.getMeta(PW_MAKE_POWER_WORD);
  if (makePowerWordTransaction) {
    await handleMakePowerWordTransaction(tr, view, formData, parent);
    setIsMakingPowerWord(false);
    return true;
  }

  const changePowerWordTransaction = tr.getMeta(PW_CHANGE_DEFINITION);
  if (changePowerWordTransaction) {
    return true;
  }

  // Handle removing power words (when deleting the text of the word itself).
  if (isDeletingText(tr)) {
    const removePowerWordTransaction = tr.getMeta(PW_REMOVE_POWER_WORD);
    // Based on this solution for preventing deleting a specific type of node
    // https://discuss.prosemirror.net/t/how-to-prevent-node-deletion/130/9
    if (!removePowerWordTransaction && countBlockType(view.state, 'powerWord_block') > countBlockType(view.state.apply(tr), 'powerWord_block')) {
      toast.error(CANT_DELETE_POWER_WORD_ERROR);
      return false;
    }
  }

  // In all other cases, continue the transaction.
  return true;
}

/**
 * Set a ProseMirror transaction to toggle Power Words mode ON/OFF
 * Exported for testing.
 * @param {Object} state ProseMirror state
 * @param {Function} dispatch ProseMirror dispatch
 */
export function setPowerWordsModeTransaction (state, dispatch) {
  const { powerWordsModeOn } = powerWordsPlugin.props;
  const { tr } = state;
  // Pass the new power words mode state through the transaction.
  tr.setMeta(PW_MODE_ON, { powerWordsModeOn: !powerWordsModeOn });
  dispatch(tr);
}

/**
 * Set a ProseMirror transaction to 'empower' a word
 * Exported for testing.
 * @param {Object} state ProseMirror state
 * @param {Function} dispatch ProseMirror dispatch
 */
export function setMakePowerWordTransaction (state, dispatch) {
  if (isMakingPowerWord) return; // Prevent multiple clicks
  setIsMakingPowerWord(true); // Set flag to prevent multiple clicks

  const { tr } = state;
  const { selection, selectedWord } = validateSelection(state);

  if (!selectedWord) {
    toast.error(NOT_SINGLE_WORD_ERROR);
    setIsMakingPowerWord(false);
    return false;
  }

  const id = cuid();
  // Pass the id and the selected word into the meta information for this transaction.
  // Because we want to wait for the successful addition of the WordDefinition (and
  // sometimes also mutation of the Word itself), we use this info to handle adding
  // the block closer to where we make those mutations.
  tr.setMeta(PW_MAKE_POWER_WORD, { id, selection, selectedWord });
  dispatch(tr);
}

/**
 * This removes a mark for a power word when the word is removed in the drawer.
 * It then re-fetches the list of power words.
 * @param {Object} view ProseMirror View
 * @param {Object} formData ArticleLevel
 * @returns {Function} called with wordForm
 */
function onRemoveWordCallback (view, formData) {
  return async ({
    articleId,
    assessment,
    wordForm,
    wordDefinition
  }) => {
    // Check if there are existing questions for this definition. If so,
    // we display a confirmation to the user before removing the definition.
    // If the user confirms, removing the definition will ALSO remove the questions.
    const questions = getPowerWordQuestions(wordDefinition.id, articleId);
    const { hasQuestions, shouldRemoveQuestions } = checkPowerWordQuestionRemoval(
      questions,
      wordForm
    );

    if (hasQuestions && !shouldRemoveQuestions) {
      return; // Don't remove the power word.
    }

    storeActions.drawer.setLoadingSpinner({ loadingState: true, label: 'Removing Power Word' });

    try {
      if (hasQuestions && shouldRemoveQuestions) {
        // Also remove the questions.
        removePowerWordQuestions(assessment, getPowerWordQuestions(wordDefinition.id, articleId));
      }

      // Remove all the power word blocks that match the definitionId or wordForm
      // It is done backwards to avoid messing up the indexes.
      // This will automatically trigger a setContent request to save the new prosemirror text.
      removePowerWordBlocksFromProsemirrorView(view, wordDefinition.id, wordForm);

      const intervalId = setInterval(async () => {
        // This delay is used to wait for the setContent request to complete before fetching the power words list again.
        // If we don't that, the UI wont remove the word from the drawer.
        const isSaving = store.getState().saveStatus.isSaving;
        if (!isSaving) {
          setStorePowerWords(await fetchPowerWords(formData, view));
          clearInterval(intervalId); // Cleanup when done
        }
      }, 1000);
    } catch (err) {
      error(`Error deleting Power Word ${wordForm}.`);
      logError(`Error deleting Power Word ${wordForm}: ${err.message}`);
    } finally {
      storeActions.drawer.setLoadingSpinner({ loadingState: false });
    }
  };
}

/**
 * removePowerWordBlocks:
 * Replaces all PowerWord blocks matching the given definitionId + wordForm
 * with their text content in the ProseMirror document.
 *
 * @param {EditorView} view - The ProseMirror editor view
 * @param {string} definitionId - The ID of the power word definition
 * @param {string} wordForm - The form of the power word
 */
function removePowerWordBlocksFromProsemirrorView (view, definitionId, wordForm) {
  const nodesToRemove = getPowerWordsNodes(
    view.state.doc,
    definitionId,
    wordForm
  );

  if (!nodesToRemove.length) {
    return; // No matching nodes found
  }

  const { tr, schema, doc } = view.state;
  // Reverse so we remove from the end first (helps avoid index shifting)
  nodesToRemove.reverse().forEach((node) => {
    const content = doc.content.textBetween(node.pos, node.pos + node.nodeSize);
    const textNode = schema.text(content);

    tr.replaceWith(node.pos, node.pos + node.nodeSize, textNode);
    tr.setMeta(PW_REMOVE_POWER_WORD, { id: definitionId });
    tr.setMeta('addToHistory', false);
  });

  view.dispatch(tr);
}

/**
 * This updates a mark for a power word when the definition is added in the drawer.
 * It then re-fetches the list of power words.
 * @param {Object} view ProseMirror View
 * @returns {Function} called with wordForm
 */
function onUpdateWordCallback (view, formData) {
  return async (newWordDefinition) => {
    // Find the power block
    view.state.doc.descendants((node, pos) => {
      if (node.type.name === 'powerWord_block' && node.attrs.id === newWordDefinition.id) {
        // "clone" the node, so we can update the attrs and re-render ProseMirror
        // This is a workaround for the fact that the prosemirror component
        // doesn't update when the attrs change.
        const newNode = node.type.create({
          ...node.attrs,
          definition: newWordDefinition.definition
        }, node.content, node.marks);
        // Update the node
        const tr = view.state.tr.replaceWith(pos, pos + node.nodeSize, newNode);
        tr.setMeta('addToHistory', false);
        view.dispatch(tr);
        // Stop the loop
        return false;
      }
    });
    setStorePowerWords(await fetchPowerWords(formData, view));
  };
}

/**
 * Open the drawer with power words and set callbacks to remove and update power words.
 * @param {Object} view ProseMirror View
 * @param {Object} formData ArticleLevel
 * @param {Object} parent Article
 */
function openDrawer (view, formData, parent) {
  const levelIndex = parent.articleLevels.findIndex((level) => level.uid === formData.uid);

  switchDrawer({
    id: parent.id, // article id
    articleLevelUid: formData.uid,
    type: 'Article',
    drawerType: POWER_WORDS,
    parent, // article
    // Right now this prevents all form errors from displaying on the
    // power word editing form. In the future, we should reconfigure this
    // to only display the relevant errors for this specific article level.
    fieldPath: `articleLevels.${levelIndex}`,
    formKey: 'powerWords',
    customDrawerTitle: 'Manage Power Words',
    onCloseCallback: disablePowerWordsMode,
    // When we remove a word definition, remove the mark in Prosemirror and re-fetch the words.
    onRemoveWordCallback: onRemoveWordCallback(view, formData),
    // When we add a word definition, update the mark in Prosemirror and re-fetch the words.
    onUpdateWordCallback: onUpdateWordCallback(view, formData)
  });
}

/**
 * Generate decorations for suggested power words.
 * Notes:
 *   - for undefined and defined power words, we use the 'powerWord' mark
 *   - ProseMirror marks are persisted in the database, and because of that
 *   - undefined and defined power words are shown in the editor
 *     even when the power words mode is off.
 * @param {Array} powerWords from query
 * @returns {Function} that returns a new DecorationSet
 */
function decorationsForSuggestedPowerWords (powerWords) {
  return (state) => {
    if (powerWords) {
      const suggestedPowerWords = powerWords.filter((word) => word.wordDefinition === null);
      return decorateWords(
        state,
        suggestedPowerWords,
        false,
        () => ({ class: 'suggestedPowerWord' })
      );
    }
  };
}

/**
 * Function that runs when transactions are dispatched. If the transaction
 * concerns the power words mode, we run the decoration function.
 * @param {Object} tr
 * @param {Object} oldState
 * @returns {Object}
 */
function applyState (tr, oldState) {
  const updatedPowerWords = tr.getMeta('decoratePowerWords');

  if (updatedPowerWords) {
    return decorationsForSuggestedPowerWords(updatedPowerWords)(tr);
  } else {
    // Pass through the current state during all other transactions.
    return passThroughTransaction(tr, oldState);
  }
}

// This is a PowerWord plugin that handles highlighting PowerWords.
// It updates the decorations when powerWords change.
const powerWordsPlugin = new Plugin({
  key: new PluginKey('powerWordsPlugin'),
  state: {
    // When initializing, don't create decorators for suggested PowerWords.
    init: noop,
    // When we've dispatched a transaction with the 'decoratePowerWords' meta, update
    // the decorators with new suggested PowerWords.
    apply: applyState
  },
  props: {
    // Set decorators based on the current plugin state.
    decorations: (state) => powerWordsPlugin.getState(state),
    // Set PowerWords mode to false initially
    powerWordsModeOn: false,
    // PowerWords list
    powerWords: []
  }
});

// Everything below will be available in powerWordsPlugin.custom
// We'll probably want to create some naming patterns here.
powerWordsPlugin.custom = {
  manageTransactions,
  onDestroy: (config) => {
    const drawerType = store.getState().drawer.data.drawerType;
    // This should only triggers in fields that has the powerWord format.
    // Only disable Power Words Mode and close the drawer if the field
    // has the powerWord format (e.g. the 'text' field in the Articles app)
    if (config?.formats?.includes('powerWord') && drawerType === POWER_WORDS) {
      disablePowerWordsMode();
      closeDrawer();
    }
  },
  format: {
    isActive: () => powerWordsPlugin.props.powerWordsModeOn,
    setPowerWordsModeTransaction,
    setMakePowerWordTransaction
  },
};

export default powerWordsPlugin;
